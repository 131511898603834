<template>
    <div class="accesslogAddEdit">
        <!-- Drawer抽屉 -->
        <Drawer :title="title" v-model="visible" width="720" on-close="handleClose()" :mask-closable="false">
            <Form ref="form" :model="form" :label-width="120" :rules="formValidate" label-position="left">
                <FormItem label="发生日期" prop="fsrq">
                    <Input v-model="form.fsrq" clearable style="width:100%"/>
                </FormItem>
                <FormItem label="手机号码" prop="telno">
                    <Input v-model="form.telno" clearable style="width:100%"/>
                </FormItem>
                <FormItem label="IMEI码" prop="imei">
                    <Input v-model="form.imei" clearable style="width:100%"/>
                </FormItem>
                <FormItem label="动作标志" prop="action">
                    <Input v-model="form.action" clearable style="width:100%"/>
                </FormItem>
                <FormItem label="请求类型" prop="lx">
                    <Input v-model="form.lx" clearable style="width:100%"/>
                </FormItem>
                <FormItem label="平台类型" prop="plaform">
                    <Input v-model="form.plaform" clearable style="width:100%"/>
                </FormItem>
                <FormItem label="执行状态" prop="exeStatus">
                    <Input v-model="form.exeStatus" clearable style="width:100%"/>
                </FormItem>
                <FormItem label="版本" prop="ver">
                    <Input v-model="form.ver" clearable style="width:100%"/>
                </FormItem>
                <FormItem label="扩展日志" prop="extLog">
                    <Input v-model="form.extLog" clearable style="width:100%"/>
                </FormItem>
                <Form-item class="br">
                    <Button @click="handleSubmit" :loading="submitLoading">保存</Button>
                    <Button @click="handleClose">关闭</Button>
                </Form-item>
            </Form>
        </Drawer>
    </div>
</template>
<script>
	// 根据你的实际请求api.js位置路径修改
	import {
		addAccesslog,
		editAccesslog
	} from "@/api/busi/data-manage/accesslog";
	export default {
		name: "accesslogAddEdit",
		components: {},
		props: {
			data: Object,
			addEditFlag: String,
			value: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				submitLoading: false, // 表单提交状态
				// TODO
				title: "本文标题",
				visible: this.value,
                form: { // 添加或编辑表单对象初始化数据
                    fsrq: "",
                    telno: "",
                    imei: "",
                    action: "",
                    lx: "",
                    plaform: "",
                    exeStatus: "",
                    ver: "",
                    extLog: "",
                },
				formExt: {
				},
                // 表单验证规则
                formValidate: {
                }
            };
        },
		methods: {
			init() {
				// 编辑情况下
				if (this.addEditFlag == "2") {
					this.form = this.data;
				}else{
					delete this.form.id;
				}
			},
			setCurrentValue(value) {
				if (value === this.visible) {
					this.$refs.form.resetFields();
				 return;
				}
				this.init();
				this.visible = value;
			},
			handleReset() {
				this.$refs.form.resetFields();
			},
			handleSubmit() {
				this.$refs.form.validate(valid => {
					if (valid) {
						if (this.addEditFlag == "1") {
							addAccesslog(this.form).then(res => {
								this.submitLoading = false;
								if (res.success) {
									this.$Message.success("操作成功");
									this.$emit("on-submit", true);
									this.visible = false;
								}
							});
						}else{
							editAccesslog(this.form).then(res => {
								this.submitLoading = false;
								if (res.success) {
									this.$Message.success("操作成功");
									this.$emit("on-submit", true);
									this.visible = false;
								}
							});
						}
					}
				});
			},
			handleClose(){
				this.visible = false;
			}

		},
		watch: {
			value(val) {
				this.setCurrentValue(val);
			},
			visible(value) {
				this.$emit("input", value);
			},
		},
		mounted() {
			this.init();
		}
	};
</script>

<style lang="less">
// 建议引入通用样式 具体路径自行修改
//@import "../../../styles/table-common.less";
</style>